exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dots-to-microdots-polkadot-js": () => import("./../../../src/pages/dots-to-microdots-polkadot.js" /* webpackChunkName: "component---src-pages-dots-to-microdots-polkadot-js" */),
  "component---src-pages-dots-to-millidots-polkadot-js": () => import("./../../../src/pages/dots-to-millidots-polkadot.js" /* webpackChunkName: "component---src-pages-dots-to-millidots-polkadot-js" */),
  "component---src-pages-dots-to-planck-polkadot-js": () => import("./../../../src/pages/dots-to-planck-polkadot.js" /* webpackChunkName: "component---src-pages-dots-to-planck-polkadot-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-microdots-to-dots-polkadot-js": () => import("./../../../src/pages/microdots-to-dots-polkadot.js" /* webpackChunkName: "component---src-pages-microdots-to-dots-polkadot-js" */),
  "component---src-pages-millidots-to-dots-polkadot-js": () => import("./../../../src/pages/millidots-to-dots-polkadot.js" /* webpackChunkName: "component---src-pages-millidots-to-dots-polkadot-js" */),
  "component---src-pages-planck-to-dots-polkadot-js": () => import("./../../../src/pages/planck-to-dots-polkadot.js" /* webpackChunkName: "component---src-pages-planck-to-dots-polkadot-js" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-public-key-to-ss-58-address-substrate-js": () => import("./../../../src/pages/public-key-to-ss58-address-substrate.js" /* webpackChunkName: "component---src-pages-public-key-to-ss-58-address-substrate-js" */),
  "component---src-pages-ss-58-address-to-public-key-substrate-js": () => import("./../../../src/pages/ss58-address-to-public-key-substrate.js" /* webpackChunkName: "component---src-pages-ss-58-address-to-public-key-substrate-js" */)
}

